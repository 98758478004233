<template>
    <div class="wrap">
        <div class="tabs">
            <ul>
                <li v-for="tab in tabList" :class="{ selected: active === tab.value }" :key="tab.value" @click="tabChange(tab.value)">{{ tab.label }}</li>
            </ul>
        </div>
        <div class="manageBox">
            <div class="top">
                <el-form :inline="true" :model="queryParams" label-width="100px" class="demo-form-inline">
                    <el-form-item>
                        <el-date-picker
                            v-model="time"
                            type="daterange"
                            clearable
                            value-format="yyyy-MM-dd"
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                        />
                    </el-form-item>
                    <el-form-item label="服务对象类别">
                        <el-autocomplete class="w200px" clearable v-model="queryParams.objectName" :fetch-suggestions="querySearchAsync" placeholder="请输入内容"></el-autocomplete>
                    </el-form-item>
                    <el-form-item label="服务内容">
                        <el-select class="w200px" v-model="queryParams.objectServiceContent" clearable placeholder="请选择服务内容">
                            <el-option v-for="item in contentList" :key="item.value" :label="item.label" :value="item.value"> </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="审核状态">
                        <el-select class="w200px" v-model="queryParams.auditState" clearable placeholder="请选择审核状态">
                            <el-option v-for="item in AUDITSTATE" :key="item.value" :label="item.label" :value="item.value"> </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="当前审核人">
                        <el-select class="w200px" v-model="approveTypeIndex" clearable placeholder="请选择当前审核人">
                            <el-option v-for="(item, index) in userList" :key="index" :label="item.name" :value="index"> </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="关键字">
                        <el-input v-model="queryParams.keyword" placeholder="请输入关键字" clearable style="width: 200px" />
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" class="search" @click="searchList">搜索</el-button>
                    </el-form-item>
                </el-form>
            </div>
            <div class="content">
                <el-table v-loading="loading" class="table" :data="tableData" border style="width: 100%" :header-cell-style="headerCellStyle">
                    <template slot="empty">
                        <TableEmpty />
                    </template>
                    <el-table-column prop="createTime" label="申请时间" width="120" />
                    <el-table-column prop="userName" label="姓名" width="120" />
                    <el-table-column prop="cardId" label="身份证号" width="120" />
                    <el-table-column prop="old" label="年龄" width="150" />
                    <el-table-column prop="sex" label="性别" width="150">
                        <template slot-scope="scope">
                            <span>{{ scope.row.sex === 0 ? '女' : '男' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="phone" label="联系电话" width="150" />
                    <el-table-column prop="homeAddress" label="家庭住址" width="150" />
                    <el-table-column prop="objectName" label="服务对象类别" width="150" />
                    <el-table-column prop="objectServiceContent" label="服务内容" width="150">
                        <template slot-scope="scope">
                            <span>{{ formatServiceContent(scope.row.objectServiceContent) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="auditState" label="审核状态" width="150">
                        <template slot-scope="scope">
                            <span>{{ getContent(AUDITSTATE, scope.row.auditState, 'label', 'text') }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="currentName" label="当前审核人" width="150" />
                    <el-table-column label="操作" align="center" fixed="right" min-width="120">
                        <template slot-scope="scope">
                            <span class="operation" @click="see(scope.row, 1)">查看</span>
                            <span class="operation" @click="see(scope.row, 2)" v-if="scope.row.operate">审核</span>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination
                    style="margin: 20px 0"
                    background
                    layout="total, sizes, prev, pager, next"
                    :total="total"
                    :page-size="pageSize"
                    :current-page="pageNum"
                    :page-sizes="[10, 20, 30, 40]"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                />
            </div>
            <!--变更和停发选择弹框-->
            <changeOrSotpApplication v-if="changeOrSotpApplicationVisible" :changeOrSotpApplicationVisible.sync="changeOrSotpApplicationVisible"></changeOrSotpApplication>
        </div>
    </div>
</template>

<script>
import changeOrSotpApplication from './components/changeOrSotpApplication'
import { list, operate, applyUserList, findObjectList } from '@/api/pensionService'
import { AUDITSTATE, SERVICECONTENT } from '@/data/dataDictionary'
import { getContent } from '@/utils/index'

export default {
    components: {
        changeOrSotpApplication
    },
    props: {
        activeType: {
            type: String,
            default: '1'
        }
    },
    data() {
        return {
            AUDITSTATE,
            active: 1,
            changeOrSotpApplicationVisible: false,
            activeName: 'second',
            total: 0,
            pageNum: 1,
            pageSize: 10,
            loading: false,
            options: [],
            tableData: [],
            time: '',
            queryParams: {
                type: 1,
                startTime: '',
                endTime: '',
                auditState: '',
                objectServiceContent: '',
                roleId: '',
                approveType: '',
                objectName: '',
                keyword: ''
            },
            userList: [],
            tabList: [
                { label: '新增申请', value: 1 },
                { label: '变更申请', value: 2 },
                { label: '停发申请', value: 3 }
            ],
            contentList: [
                { label: '助老服务', value: 1 },
                { label: '信息服务', value: 2 }
            ],
            approveTypeIndex: ''
        }
    },
    mounted() {
        this.applyUserList()
        // this.findObjectList()
        console.log(this.activeType)
        this.getList()
    },
    methods: {
        getContent,
        async findObjectList() {
            const res = await findObjectList()
            console.log(res.data)
        },
        tabChange(value) {
            this.active = value
            this.queryParams.type = value
            this.pageNum = 1
            this.getList()
        },
        see(item, type) {
            this.$router.push({
                path: '/homeCasedCareServices/applicationList/applicationExamine',
                query: { id: item.id, type: type }
            })
        },
        /*审核*/
        async applyUserList() {
            const res = await applyUserList()
            this.userList = res.data
        },
        /*tab切换*/
        handleClick(tab, event) {
            console.log(tab, event)
        },
        // 管理人员列表
        async getList() {
            this.loading = true
            if (this.time) {
                this.queryParams.startTime = this.time[0]
                this.queryParams.endTime = this.time[1]
            } else {
                this.queryParams.startTime = ''
                this.queryParams.endTime = ''
            }
            const data = {
                pageNum: this.pageNum,
                pageSize: this.pageSize
            }
            if (this.approveTypeIndex !== '') {
                let item = this.userList[this.approveTypeIndex]
                this.queryParams.roleId = item.roleId
                this.queryParams.approveType = item.type
            } else {
                this.queryParams.roleId = ''
                this.queryParams.approveType = ''
            }
            const res = await list({ ...data, ...this.queryParams })
            if (res.state === 0) {
                this.loading = false
                this.tableData = res.data.rows
                this.total = res.data.total
            }
        },
        searchList() {
            this.pageNum = 1
            this.getList()
        },
        // 翻页
        handleCurrentChange(val) {
            this.loading = true
            this.pageNum = val
            this.getList()
        },
        handleSizeChange(val) {
            this.loading = true
            this.pageSize = val
            this.getList()
        },
        // 表格样式
        tableRowClassName({ row, rowIndex }) {
            if (rowIndex % 2 === 0) {
                return 'statistics-warning-row'
            } else {
                return ''
            }
        },
        formatServiceContent(vals) {
            let arr = vals.split(',')
            let newArr = arr.map(element => {
                let val = SERVICECONTENT.find(item => item.value === Number(element)).label
                return val
            })
            return newArr.join()
        },
        querySearchAsync(queryString, cb) {
            let result = []
            findObjectList({ serviceObjectType: queryString }).then(res => {
                if (res.data.length) {
                    res.data.forEach(item => {
                        result.push({
                            value: item.name,
                            id: item.id
                        })
                    })
                }
            })
            cb(result)
        }
    }
}
</script>

<style lang="scss" scoped>
.wrap {
    padding: 20px;
    background: #fff;
}
.tabs {
    margin-bottom: 15px;
    background: #fff;
    box-sizing: border-box;
    ul {
        display: flex;
        padding: 0;
        margin: 0;
        height: 40px;
        border-bottom: solid 2px #e4e7ed;
        box-sizing: border-box;
    }
    li {
        height: 40px;
        margin-right: 30px;
        line-height: 40px;
        font-weight: 500;
        color: #303133;
        font-size: 0.083333rem;
        box-sizing: border-box;
        list-style: none;
        cursor: pointer;
        &.selected {
            color: #3fcf89;
            border-bottom: solid 2px #3fcf89;
        }
    }
}
::v-deep {
    .roleColumn {
        max-width: 350px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        display: inherit;
    }
}
.manageBox {
    // margin: 20px;
    background: #fff;
    min-height: calc(100vh - 130px);
    .top {
        /*padding: 20px 15px;*/
        /*border-bottom: 2px solid #f5f7fa;*/
        .search {
            margin-left: 20px;
        }
        .add {
            float: right;
        }
    }
    .eyes {
        // display: inline-block;
        width: 20px;
        height: 20px;
        float: right;
        margin-right: 20px;
        cursor: pointer;
    }
    .operation {
        margin-right: 20px;
        color: #3fcf89;
        cursor: pointer;
    }
    .stop {
        color: #f57473;
    }
    .widthStyle {
        width: 90%;
    }

    /*      /deep/ .el-form-item__content {
            margin-left: 55px !important;
            margin-right: 55px;
        }*/
    /deep/ .el-dialog__title {
        font-size: 16px;
    }
    /deep/ .el-dialog__header {
        font-weight: 600;
        color: #333333;
    }
    /deep/ .el-dialog__body {
        padding-left: 40px;
    }
    /deep/ .el-dialog__footer {
        text-align: center;
        padding-left: 40px;
    }
}
.content {
    /*padding: 15px;*/
    padding-top: 20px;
}
.my_btn_duty {
    margin-right: 20px;
}
.el-button--text {
    outline: none;
    border: none;
}
.el-button--text:hover,
.el-button--text:focus {
    background: none !important;
    border: none !important;
    outline: none !important;
}
.el-button.is-disabled,
.el-button.is-disabled:hover,
.el-button.is-disabled:focus {
    color: #c0c4cc !important;
}
.w200px {
    width: 200px;
}
</style>
