<template>
  <div>
      <el-dialog
          title="提示"
          :visible.sync="changeOrSotpApplicationVisible"
          width="50%"
          :before-close="handleClose">
          <div>
              <el-table
                  v-loading="loading"
                  :data="tableData"
                  border
                  :row-style="{ height: '55px' }"
                  style="width: 100%"
                  :row-class-name="tableRowClassName"
                  :header-cell-style="{ color: '#333333', fontWeight: 600 }"
                  class="content-itme"
              >
                  <el-table-column prop="phone" label="开始服务时间" width="120" />
                  <el-table-column prop="phone" label="姓名" width="120" />
                  <el-table-column prop="roleName" label="身份证号" width="120" />
                  <el-table-column prop="createTime" label="年龄" width="150" />
                  <el-table-column prop="createTime" label="联系电话" width="150" />
                  <el-table-column prop="createTime" label="所属区划" width="150" />
                  <el-table-column prop="createTime" label="家庭地址" width="150" />
                  <el-table-column prop="createTime" label="服务对象类别" width="150" />
                  <el-table-column prop="address" label="操作"   fixed="right" min-width="100">
                      <template slot-scope="scope">
                          <el-button type="text" class="operation" @click="handlerChange">选择</el-button>
                      </template>
                  </el-table-column>
              </el-table>
              <el-pagination
                  style="margin:20px 0"
                  background
                  layout="total, sizes, prev, pager, next"
                  :total="total"
                  :page-size="pageSize"
                  :current-page="pageNum"
                  :page-sizes="[10, 20, 30, 40]"
                  @size-change="handleSizeChange"
                  @current-change="handleCurrentChange"
              />
          </div>
          <span slot="footer" class="dialog-footer">
    <el-button @click="handleClose">取 消</el-button>
    <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
  </span>
      </el-dialog>
  </div>
</template>

<script>
  export default {
    name: "changeOrSotpApplication",
    props: {
      changeOrSotpApplicationVisible: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        total: 0,
        pageNum: 1,
        pageSize: 10,
        loading: false,
        options: [],
        tableData: [{
          phone: 1
        }],
        queryParams: {
          date: ''
        }
      }
    },
    methods: {
      handleClose() {
        this.$emit('update:changeOrSotpApplicationVisible', false)
      },
      /*选择*/
      handlerChange() {

        this.$router.push({path: '/homeCasedCareServices/applicationList/applicationEdit'})
      },
      // 管理人员列表
      async selectManager(num) {
        if (num) {
          this.pageNum = num
        }
        this.loading = true
        const data = {
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          search: this.keyWord
        }
        const res = await selectManager(data)
        if (res.state === 0) {
          this.loading = false
          this.tableData = res.data.rows
          this.total = res.data.total
        }
      },
      // 翻页
      handleCurrentChange(val) {
        this.loading = true
        this.pageNum = val
        this.selectManager()
      },
      handleSizeChange(val) {
        this.loading = true
        this.pageSize = val
        this.selectManager()
      },
      // 表格样式
      tableRowClassName({ row, rowIndex }) {
        if (rowIndex % 2 === 0) {
          return 'statistics-warning-row'
        } else {
          return ''
        }
      },
    }
  }
</script>

<style scoped>

</style>
