import request from '../utils/request'
import config from './config'

// 当前审核人下拉框列表
export function applyUserList(params) {
	return request({
		url: `${config.CIVIL_HOST}/pensionServiceApply/applyUserList`,
		method: 'get',
		params: params,
	})
}
// 申请详情
export function details(params) {
	return request({
		url: `${config.CIVIL_HOST}/pensionServiceApply/details`,
		method: 'get',
		params: params,
	})
}

// 附件列表列表
export function findProcess(params) {
	return request({
		url: `${config.CIVIL_HOST}/pensionServiceApply/findProcess`,
		method: 'get',
		params: params,
	})
}
// 助老服务统计
export function helpAgeServiceStatistics(data) {
	return request({
		url: `${config.CIVIL_HOST}/pensionServiceApply/helpAgeServiceStatistics`,
		method: 'post',
		data: data,
	})
}

// 机构-助老服务统计列表下载
export function listDowExcel(data) {
	return request({
		url: `${config.CIVIL_HOST}/pensionServiceApply/helpAgeServiceStatistics/listDowExcel`,
		method: 'post',
		data: data,
		responseType: 'blob',
	})
}
// 申请列表
export function list(params) {
	return request({
		url: `${config.CIVIL_HOST}/pensionServiceApply/list`,
		method: 'get',
		params: params,
	})
}
// 审核
export function operate(data) {
	return request({
		url: `${config.CIVIL_HOST}/pensionServiceApply/operate`,
		method: 'post',
		data: data,
	})
}

// 服务对象类别列表
export function findObjectList(params) {
	return request({
		url: `${config.CIVIL_HOST}/pensionServiceApply/findObjectList`,
		method: 'get',
		params: params,
	})
}

// 人员列表-详情
export function peopleDetail(params,id) {
	return request({
		url: `${config.CIVIL_HOST}/pensionServiceApply/people/detail/${id}`,
		method: 'get',
		params
	})
}
// 人员列表-导出
export function excelDow(params) {
	return request({
		url: `${config.CIVIL_HOST}/pensionServiceApply/people/excelDow`,
		method: 'get',
		params: params,
		responseType: 'blob',
	})
}
// 人员列表-分页列表
export function pageList(params) {
	return request({
		url: `${config.CIVIL_HOST}/pensionServiceApply/people/page`,
		method: 'get',
		params: params,
	})
}